import React from 'react'
import { SectionTitle } from 'components/section-title/section-title'
import { Tag } from 'components/tag/tag'
import { DateText } from 'components/date-text/date-text'
import styled from 'styled-components'
import { Title } from 'components/title/title'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { transition } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'

export type MostReadItem = {
	url: string
	title: string
	category?: {
		href: string
		label: string
	}
	timestamp?: string | null
}
type MostReadProps = {
	title: string
	items: MostReadItem[]
}

const MostReadWrapper = styled.div`
	padding-top: 14px;
`

const MostReadList = styled.div`
	margin-top: 2px;

	${mediaBreakpointUpLg} {
		margin-top: 0;
	}
`

const MostReadListItem = styled.div`
	padding: 15px 0 14px;

	&:not(:last-child) {
		border-bottom: 1px solid ${props => props.theme.colors.lightPeriwinkle};
	}

	&:last-child {
		padding: 15px 0 8px;
	}
`

const MostReadTitle = styled(Title)`
	margin: 0 0 5px;
	font-size: 16px;
	line-height: 22px;
	transition: ${transition('color')};
`

const MostReadLink = styled(NextLink)`
	&:hover {
		text-decoration: none;

		${MostReadTitle} {
			color: ${props => props.theme.text.secondary};
		}
	}
`

export const MostReadItemRow = (item: MostReadItem, index: number, showTag = true) => {
	return (
		<MostReadListItem key={index}>
			{showTag && item.category && (
				<Tag href={item.category.href} transparent>
					{item.category.label}
				</Tag>
			)}

			<MostReadLink href={item.url} passHref>
				<MostReadTitle size={3} maxLines={3}>
					{item.title}
				</MostReadTitle>
			</MostReadLink>
			<DateText timestamp={item.timestamp || ''} />
		</MostReadListItem>
	)
}

const MostReadComponent = ({ title, items }: MostReadProps) => {
	return (
		<MostReadWrapper>
			<SectionTitle suffix="most-read-title" isSidebar>
				{title}
			</SectionTitle>
			<MostReadList>{items.map((item, index) => MostReadItemRow(item, index))}</MostReadList>
		</MostReadWrapper>
	)
}

export const MostRead = React.memo(MostReadComponent)
