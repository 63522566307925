import React, { useState } from 'react'
import { LazyImage } from 'components/lazy-image/lazy-image'
import styled from 'styled-components'
import { useInterval } from 'react-use'
import { positionAbsoluteAndStretch, paddingBottomByAspectRatio, boxShadowRgbaWithHex, transition } from 'theme/utils'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { NextLink } from 'components/next-link/next-link'
import { useInView } from 'react-intersection-observer'

export enum OrientationTypes {
	vertical = 'vertical',
	horizontal = 'horizontal',
}

type AdvertisementPropsItem = {
	src: string
	srcRetina: string
	srcPlaceholder: string
	alt: string
	url: string
}
type AdvertisementProps = {
	items: AdvertisementPropsItem[]
	orientation?: OrientationTypes
}

const AdvertisementWrapper = styled.div`
	width: 100%;
	max-width: 288px;
	height: auto;
	margin: 0 auto;

	${mediaBreakpointUpLg} {
		max-width: none;
	}
`

const AdvertisementInner = styled.div<{ $aspectRatio: string }>`
	position: relative;
	${props => paddingBottomByAspectRatio(props.$aspectRatio)}
`

const AdvertisementLink = styled.a<{ $isVisible: boolean }>`
	${positionAbsoluteAndStretch}
	display: block;
	overflow: hidden;
	pointer-events: ${props => (props.$isVisible ? 'initial' : 'none')};
	border-radius: 2px;
	opacity: ${props => (props.$isVisible ? 1 : 0)};
	transition: ${transition('opacity')};
	${props => boxShadowRgbaWithHex('0 1px 20px 0', props.theme.colors.veryLightPink, 0.3)}
`

const LazyImageContainer = styled.div<{ $isVisible: boolean }>`
	${positionAbsoluteAndStretch}
	display: block;
	overflow: hidden;
	pointer-events: ${props => (props.$isVisible ? 'initial' : 'none')};
	opacity: ${props => (props.$isVisible ? 1 : 0)};
	transition: ${transition('opacity')};
`

const AdvertisementComponent = ({ items, orientation = OrientationTypes.vertical }: AdvertisementProps) => {
	const { isMobile } = useMediaQueryContext()
	const isVertical = orientation === OrientationTypes.vertical
	const props = {
		aspectRatio: isVertical ? '350/560' : isMobile ? '288/200' : '1110/240',
	}
	const [visibleAdvertisement, setVisibleAdvertisement] = useState(0)
	const { ref, inView } = useInView()

	useInterval(
		() => {
			if (visibleAdvertisement < items.length - 1) {
				setVisibleAdvertisement(visibleAdvertisement + 1)
			} else {
				setVisibleAdvertisement(0)
			}
		},
		inView ? 6000 : null
	)

	const renderAdvertisement = (item: AdvertisementPropsItem, index: number) => {
		if (item.url) {
			return (
				<NextLink href={item.url} key={index} passHref>
					<AdvertisementLink $isVisible={visibleAdvertisement === index}>
						<LazyImage {...item} {...props} />
					</AdvertisementLink>
				</NextLink>
			)
		}

		return (
			<LazyImageContainer $isVisible={visibleAdvertisement === index} key={index}>
				<LazyImage {...item} {...props} />
			</LazyImageContainer>
		)
	}

	return (
		<AdvertisementWrapper ref={ref}>
			<AdvertisementInner $aspectRatio={props.aspectRatio}>{items.map(renderAdvertisement)}</AdvertisementInner>
		</AdvertisementWrapper>
	)
}

export const Advertisement = React.memo(AdvertisementComponent)
