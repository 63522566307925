import React from 'react'
import styled, { css } from 'styled-components'
import { Text, TextTypes } from 'components/text/text'
import { useFormattedDate } from 'hooks/use-formatted-date'
import { DateFormats } from 'utils/dates'
import { parseISO, formatISO, isSameMonth, isSameDay } from 'date-fns'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

type DateRangeTextProps = {
	start?: string
	end: string
}

const baseFontStyle = css`
	font-weight: 700;
	color: ${props => props.theme.color.primary};
`

const DateRangeTextWrapper = styled.div<{ $sameMonth: boolean; $singleDate: boolean }>`
	display: flex;
	justify-content: flex-end;
	width: ${props => (props.$singleDate ? '60px' : props.$sameMonth ? '110px' : '140px')};
`

const DateRangeSeparator = styled.span`
	position: relative;
	display: block;
	width: 4px;
	margin: 0 4px;
	${baseFontStyle}

	&::after {
		position: absolute;
		top: 8px;
		right: 0;
		left: 0;
		height: 2px;
		content: ' ';
		background: ${props => props.theme.color.primary};
	}
`

const DateText = styled(Text)`
	margin-bottom: 0;
	line-height: 19px;
	${baseFontStyle}
`

const DateRangeTextComponent = ({ start = '', end }: DateRangeTextProps) => {
	const { language } = useLocationContext()

	const startDate = parseISO(start)
	const endDate = parseISO(end)
	const startDateTimestamp = formatISO(startDate)
	const endDateTimestamp = formatISO(endDate)
	const datesAreOnSameMonth = isSameMonth(startDate, endDate)
	const datesAreOnSameDay = isSameDay(startDate, endDate)

	let startDateFormatted = useFormattedDate(startDateTimestamp, language, DateFormats.day)
	if (datesAreOnSameMonth) {
		if (language === Language.Pt) {
			startDateFormatted = startDateFormatted.split(' ')[0]
		}
	}
	let endDateFormatted = useFormattedDate(endDateTimestamp, language, DateFormats.day)
	if (datesAreOnSameMonth) {
		if (language === Language.En) {
			endDateFormatted = endDateFormatted.split(' ')[1]
		}
	}

	const startDateFormattedLocale = useFormattedDate(startDateTimestamp, language, DateFormats.locale)
	const endDateFormattedLocale = useFormattedDate(endDateTimestamp, language, DateFormats.locale)

	let dateRangeTitle = endDateFormattedLocale
	if (startDateTimestamp && !datesAreOnSameDay) {
		dateRangeTitle = `${startDateFormattedLocale} - ${dateRangeTitle}`
	}

	return (
		<DateRangeTextWrapper $sameMonth={datesAreOnSameMonth} $singleDate={!start} title={dateRangeTitle}>
			{start && !datesAreOnSameDay && (
				<DateText textType={TextTypes.paragraph}>
					<time dateTime={start}>{startDateFormatted}</time>
				</DateText>
			)}
			{start && !datesAreOnSameDay && <DateRangeSeparator />}
			<DateText textType={TextTypes.paragraph}>
				<time dateTime={end}>{endDateFormatted}</time>
			</DateText>
		</DateRangeTextWrapper>
	)
}

export const DateRangeText = React.memo(DateRangeTextComponent)
