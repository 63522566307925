import React from 'react'
import styled from 'styled-components'
import { SvgPlaySmall } from 'components/svgs/svg-play-small'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { Tag } from 'components/tag/tag'
import { Title } from 'components/title/title'
import { DateText } from 'components/date-text/date-text'
import { rgbaWithHex } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'

type NewsCardVideoMobileProps = {
	id: string | number
	url: string
	title: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
}

const NewsCardVideoMobileWrapper = styled.article`
	display: flex;
	padding: 8px 0 12px;
`

const NewsCardVideoMobileThumbnail = styled.div`
	position: relative;
	flex: 0 0 70px;
	height: 70px;
	margin-top: 4px;
`

const NewsCardVideoMobileLink = styled(NextLink)`
	position: absolute;
	inset: 0;
`

const NewsCardVideoMobileIconWrapper = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	${props => rgbaWithHex(props.theme.colors.black, 0.5, 'background')}
`

const NewsCardVideoMobileIcon = styled(NextLink)`
	padding: 4px;
	pointer-events: all;
`

const NewsCardVideoMobileBody = styled.div`
	flex-grow: 1;
	padding-left: 8px;
`

const NewsCardVideoMobileHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-top: 1px;
`

const NewsCardVideoMobileDateText = styled.div`
	padding-top: 4px;
`

const NewsCardVideoMobileTitleLink = styled(NextLink)`
	&:hover {
		text-decoration: none;
	}
`

const NewsCardVideoMobileTitle = styled(Title)`
	margin-top: 4px;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;

	&:hover {
		color: ${props => props.theme.text.secondary};
	}
`

const NewsCardVideoMobileComponent = ({ id, url, thumbnail, title, category, timestamp }: NewsCardVideoMobileProps) => {
	return (
		<NewsCardVideoMobileWrapper>
			<NewsCardVideoMobileThumbnail>
				<NewsCardVideoMobileLink href={url} passHref>
					<LazyImage {...thumbnail} aspectRatio="70/70" />
				</NewsCardVideoMobileLink>
				<NewsCardVideoMobileIconWrapper>
					<NewsCardVideoMobileIcon href={url} passHref>
						<SvgPlaySmall suffix={`-${id}`} />
					</NewsCardVideoMobileIcon>
				</NewsCardVideoMobileIconWrapper>
			</NewsCardVideoMobileThumbnail>
			<NewsCardVideoMobileBody>
				<NewsCardVideoMobileHeader>
					<Tag href={category.href} transparent>
						{category.label}
					</Tag>
					<NewsCardVideoMobileDateText>
						<DateText timestamp={timestamp} />
					</NewsCardVideoMobileDateText>
				</NewsCardVideoMobileHeader>
				<NewsCardVideoMobileTitleLink href={url} passHref>
					<NewsCardVideoMobileTitle size={3} maxLines={2}>
						{title}
					</NewsCardVideoMobileTitle>
				</NewsCardVideoMobileTitleLink>
			</NewsCardVideoMobileBody>
		</NewsCardVideoMobileWrapper>
	)
}

export const NewsCardVideoMobile = React.memo(NewsCardVideoMobileComponent)
