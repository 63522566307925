import React from 'react'
import { Text, TextTypes, TextWeights } from 'components/text/text'
import styled from 'styled-components'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { NextLink } from 'components/next-link/next-link'

export enum FeaturedSizes {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

type FeaturedProps = {
	title: string
	description: string
	url: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	size: FeaturedSizes
}

export const featuredImageSizes = {
	[FeaturedSizes.SMALL]: { width: 60, height: 60, margin: 8 },
	[FeaturedSizes.MEDIUM]: { width: 120, height: 78, margin: 16 },
	[FeaturedSizes.LARGE]: { width: 250, height: 78, margin: 30 },
}

const FeaturedWrapper = styled.div`
	display: flex;
`

const FeaturedImage = styled.div<{ size: FeaturedSizes }>`
	flex: 0 0 ${props => featuredImageSizes[props.size].width + 'px'};
	width: ${props => featuredImageSizes[props.size].width + 'px'};
	height: ${props => featuredImageSizes[props.size].height + 'px'};
	margin-right: ${props => featuredImageSizes[props.size].margin + 'px'};
	overflow: hidden;
	border-radius: 2px;
`

const FeaturedTitle = styled(Text)`
	padding-top: 3px;
	margin: 0 0 6px;
	line-height: 1;
	letter-spacing: -0.1px;
`

const FeaturedDescription = styled(Text)`
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.1px;
`

const FeaturedLink = styled(NextLink)`
	&:hover {
		text-decoration: none;

		${FeaturedTitle},
		${FeaturedDescription} {
			color: ${props => props.theme.text.secondary};
		}
	}
`

const FeaturedComponent = ({ title, description, url, thumbnail, size = FeaturedSizes.MEDIUM }: FeaturedProps) => {
	const aspectRatio = `${featuredImageSizes[size].width}/${featuredImageSizes[size].height}`
	return (
		<FeaturedLink href={url} passHref>
			<FeaturedWrapper>
				<FeaturedImage size={size}>
					<LazyImage {...thumbnail} aspectRatio={aspectRatio} />
				</FeaturedImage>
				<div>
					<FeaturedTitle textType={TextTypes.body} weight={TextWeights.bold}>
						{title}
					</FeaturedTitle>
					<FeaturedDescription textType={TextTypes.paragraph} maxLines={3}>
						{description}
					</FeaturedDescription>
				</div>
			</FeaturedWrapper>
		</FeaturedLink>
	)
}

export const Featured = React.memo(FeaturedComponent)
