import React from 'react'
import { SectionTitle } from 'components/section-title/section-title'
import { DateRangeText } from 'components/date-range-text/date-range-text'
import styled from 'styled-components'
import { Title } from 'components/title/title'
import { Text } from 'components/text/text'
import { PrimaryButton } from 'components/primary-button/primary-button'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { NextLink } from 'components/next-link/next-link'
import { wordWrap } from 'theme/utils'

type NextEventsItem = {
	url: string
	title: string
	startDate?: string
	endDate: string
	address: string
}
type NextEventsProps = {
	title: string
	items: NextEventsItem[]
	link: {
		label: string
		href: string
	}
}

const NextEventsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 14px;

	${mediaBreakpointUpLg} {
		align-items: flex-start;
	}
`

const NextEventsList = styled.div`
	width: 100%;
	margin-top: 2px;
	margin-bottom: 17px;
	overflow: hidden;

	${mediaBreakpointUpLg} {
		margin-top: 0;
	}
`

const NextEventsFlex = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 18px 0 14px;

	&:not(:last-child) {
		border-bottom: 1px solid ${props => props.theme.colors.lightPeriwinkle};
	}
`

const NextEventsInfo = styled.div`
	${mediaBreakpointUpLg} {
		max-width: 200px;
	}
`

const NextEventsTitle = styled(Title)`
	margin-top: -1px;
	margin-bottom: 2px;
	font-size: 16px;
	line-height: 21px;
	${wordWrap}
`

const NextEventsLocation = styled(Text)`
	margin-bottom: 0;
	line-height: 19px;
	color: ${props => props.theme.text.light};
	${wordWrap}
`

const NextEventsLink = styled(NextLink)`
	&:hover {
		text-decoration: none;

		${NextEventsTitle} {
			color: ${props => props.theme.text.secondary};
		}

		${NextEventsLocation} {
			color: ${props => props.theme.text.secondary};
		}
	}
`

const NextEventsComponent = ({ title, items, link }: NextEventsProps) => {
	return (
		<NextEventsWrapper>
			<SectionTitle suffix="next-events-title" isSidebar>
				{title}
			</SectionTitle>
			<NextEventsList>
				{items.map((item, index) => (
					<NextEventsFlex key={index}>
						<NextEventsInfo>
							<NextEventsLink href={item.url} passHref>
								<>
									<NextEventsTitle size={3}>{item.title}</NextEventsTitle>
									<NextEventsLocation maxLines={1}>{item.address}</NextEventsLocation>
								</>
							</NextEventsLink>
						</NextEventsInfo>
						<div>
							<DateRangeText start={item.startDate} end={item.endDate} />
						</div>
					</NextEventsFlex>
				))}
			</NextEventsList>
			<PrimaryButton suffix="next-events-link" href={link.href}>
				{link.label}
			</PrimaryButton>
		</NextEventsWrapper>
	)
}

export const NextEvents = React.memo(NextEventsComponent)
